import { defineStore } from 'pinia'
import { STORE_ID } from '@/constants/store'
import type { GiftCardsState as State } from '@/types/stores/gift-cards'
import type {
  PersonaliseSubmitOrderParams,
  RecipientsSubmitOrderParams
} from '@/components/gift-cards/wizard/types/submit'
import { toTimezoneUnspecifiedDateTimeFormat } from '@/utils/string'
import { parse, stringify } from 'zipson'

const initialState = (): State => ({
  wizard: {
    activeStep: 1,
    target: 'purchaser',
    scheduled: false,
    values: {
      skin: undefined,
      personalisation: {
        presetAmount: undefined,
        customAmount: undefined,
        quantity: 1,
        purchaserGivenName: undefined,
        purchaserFamilyName: undefined,
        purchaserEmail: undefined,
        scheduledDateTime: undefined
      },
      recipients: {
        message: undefined,
        recipients: [{ name: '', emailAddress: '' }]
      }
    }
  },
  data: {
    skinGroups: []
  }
})

const useGiftCardsStore = defineStore(STORE_ID.GIFT_CARDS, {
  state: initialState,
  getters: {
    isForRecipient: (state) => state.wizard.target === 'recipient'
  },
  actions: {
    /**
     * @description Returns the body for the API request on the Personalisation step
     * @returns PersonaliseSubmitOrderParams['body'] | false
     */
    getBodyForSubmissionOnPersonaliseStep(): Omit<PersonaliseSubmitOrderParams['body'], 'recaptchaResponse'> | false {
      const { skin, personalisation } = this.wizard.values

      // Validate the amount fields
      if (!personalisation.presetAmount && !personalisation.customAmount) {
        console.warn('Amount fields are not valid')
        return false
      }

      // Validate the purchaser fields
      if (
        !personalisation.purchaserGivenName ||
        !personalisation.purchaserFamilyName ||
        !personalisation.purchaserEmail
      ) {
        console.warn('Purchaser fields are not valid')
        return false
      }

      return {
        skinId: skin?.id || this.data.skinGroups[0].skins[0].id,
        amount: personalisation.presetAmount || (personalisation.customAmount as number) * 100,
        quantity: personalisation.quantity,
        purchaser: {
          givenName: personalisation.purchaserGivenName,
          familyName: personalisation.purchaserFamilyName,
          emailAddress: personalisation.purchaserEmail
        },
        scheduledDateTime:
          this.wizard.scheduled && personalisation.scheduledDateTime
            ? toTimezoneUnspecifiedDateTimeFormat(new Date(personalisation.scheduledDateTime))
            : undefined,
        recipients: null
      }
    },
    /**
     * @description Returns the body for the API request on the Recipients step
     */
    getBodyForSubmissionOnRecipientsStep(): Omit<RecipientsSubmitOrderParams['body'], 'recaptchaResponse'> | false {
      const { recipients, personalisation, skin } = this.wizard.values

      // Validate the amount fields
      if (!personalisation.presetAmount && !personalisation.customAmount) {
        console.warn('Amount fields are not valid')
        return false
      }

      // Validate the purchaser fields
      if (
        !personalisation.purchaserGivenName ||
        !personalisation.purchaserFamilyName ||
        !personalisation.purchaserEmail
      ) {
        console.warn('Purchaser fields are not valid')
        return false
      }

      // Validate quantity
      if (!personalisation.quantity) {
        return false
      }

      // Validate the recipients fields
      if (!recipients.recipients.length) {
        return false
      }

      return {
        skinId: skin?.id || this.data.skinGroups[0].skins[0].id,
        amount: personalisation.presetAmount || (personalisation.customAmount as number) * 100,
        quantity: personalisation.quantity,
        message: recipients.message,
        purchaser: {
          givenName: personalisation.purchaserGivenName,
          familyName: personalisation.purchaserFamilyName,
          emailAddress: personalisation.purchaserEmail
        },
        scheduledDateTime:
          this.wizard.scheduled && personalisation.scheduledDateTime
            ? toTimezoneUnspecifiedDateTimeFormat(new Date(personalisation.scheduledDateTime))
            : undefined,
        recipients: recipients.recipients.toSpliced(personalisation.quantity)
      }
    }
  },
  persist: {
    key: `${STORE_ID.GIFT_CARDS}-v1`,
    storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
      maxAge: 60 * 60 * 6
    }),
    serializer: {
      deserialize: parse,
      serialize: stringify
    },
    paths: ['wizard']
  }
})
export default useGiftCardsStore
